
const FolderIcon = ({
    color = "#FFFFFF"
}) => {

    return <>
        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7479 6.03024C20.624 5.85471 20.4611 5.71178 20.2725 5.61323C20.084 5.51468 19.8753 5.46333 19.6635 5.46341H17.9453V3.70732C17.9453 3.34507 17.8046 2.99766 17.5539 2.74151C17.3033 2.48537 16.9634 2.34146 16.609 2.34146H9.99117C9.94986 2.34146 9.90967 2.32777 9.87662 2.30244L7.22968 0.273171C6.99817 0.0962298 6.71695 0.000420188 6.42787 0H1.33636C0.981932 0 0.642025 0.143902 0.39141 0.400049C0.140794 0.656197 0 1.00361 0 1.36585V15.4146C0 15.5699 0.0603403 15.7188 0.167747 15.8286C0.275153 15.9383 0.420828 16 0.572724 16H17.6685C17.7887 16 17.9058 15.9613 18.0032 15.8896C18.1007 15.8178 18.1736 15.7165 18.2117 15.6L20.9311 7.26146C20.9983 7.05608 21.0167 6.83733 20.9848 6.62326C20.9529 6.40919 20.8717 6.20593 20.7479 6.03024ZM1.33636 1.17073H6.42787C6.46918 1.17073 6.50937 1.18442 6.54242 1.20976L9.18935 3.23902C9.42087 3.41596 9.70209 3.51177 9.99117 3.5122H16.609C16.6596 3.5122 16.7082 3.53275 16.744 3.56934C16.7798 3.60594 16.7999 3.65557 16.7999 3.70732V5.46341H4.17802C3.8976 5.46343 3.62429 5.5536 3.39679 5.72116C3.16929 5.88873 2.99912 6.12519 2.91039 6.39707L1.14545 11.8049V1.36585C1.14545 1.3141 1.16556 1.26447 1.20136 1.22788C1.23717 1.19129 1.28572 1.17073 1.33636 1.17073ZM19.8449 6.89073L17.2562 14.8293H1.36786L3.99666 6.76781C4.0093 6.72887 4.03363 6.69499 4.06619 6.671C4.09875 6.647 4.13788 6.63411 4.17802 6.63415H19.6635C19.6938 6.63412 19.7236 6.64143 19.7505 6.65549C19.7774 6.66955 19.8007 6.68995 19.8184 6.715C19.8362 6.74006 19.8478 6.76906 19.8524 6.79961C19.857 6.83016 19.8544 6.86139 19.8449 6.89073Z" fill={color}/>
        </svg>
    </>
}
export default FolderIcon

{/* <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.7479 6.03024C20.624 5.85471 20.4611 5.71178 20.2725 5.61323C20.084 5.51468 19.8753 5.46333 19.6635 5.46341H17.9453V3.70732C17.9453 3.34507 17.8046 2.99766 17.5539 2.74151C17.3033 2.48537 16.9634 2.34146 16.609 2.34146H9.99117C9.94986 2.34146 9.90967 2.32777 9.87662 2.30244L7.22968 0.273171C6.99817 0.0962298 6.71695 0.000420188 6.42787 0H1.33636C0.981932 0 0.642025 0.143902 0.39141 0.400049C0.140794 0.656197 0 1.00361 0 1.36585V15.4146C0 15.5699 0.0603403 15.7188 0.167747 15.8286C0.275153 15.9383 0.420828 16 0.572724 16H17.6685C17.7887 16 17.9058 15.9613 18.0032 15.8896C18.1007 15.8178 18.1736 15.7165 18.2117 15.6L20.9311 7.26146C20.9983 7.05608 21.0167 6.83733 20.9848 6.62326C20.9529 6.40919 20.8717 6.20593 20.7479 6.03024ZM1.33636 1.17073H6.42787C6.46918 1.17073 6.50937 1.18442 6.54242 1.20976L9.18935 3.23902C9.42087 3.41596 9.70209 3.51177 9.99117 3.5122H16.609C16.6596 3.5122 16.7082 3.53275 16.744 3.56934C16.7798 3.60594 16.7999 3.65557 16.7999 3.70732V5.46341H4.17802C3.8976 5.46343 3.62429 5.5536 3.39679 5.72116C3.16929 5.88873 2.99912 6.12519 2.91039 6.39707L1.14545 11.8049V1.36585C1.14545 1.3141 1.16556 1.26447 1.20136 1.22788C1.23717 1.19129 1.28572 1.17073 1.33636 1.17073ZM19.8449 6.89073L17.2562 14.8293H1.36786L3.99666 6.76781C4.0093 6.72887 4.03363 6.69499 4.06619 6.671C4.09875 6.647 4.13788 6.63411 4.17802 6.63415H19.6635C19.6938 6.63412 19.7236 6.64143 19.7505 6.65549C19.7774 6.66955 19.8007 6.68995 19.8184 6.715C19.8362 6.74006 19.8478 6.76906 19.8524 6.79961C19.857 6.83016 19.8544 6.86139 19.8449 6.89073Z" fill="white"></path></svg> */}