import { useEffect } from 'react';
import './style.css'
const RetailerOnBoardingReceived =({ handleNext })=>{
    useEffect(() => {
        // Set a timeout of 5 seconds
        const timer = setTimeout(() => {
          handleNext();
        }, 2000);
    
       
        return () => clearTimeout(timer);
      }, []);
    return (
        <>
        {/* main div component */}
        <div className="w-full h-full p-6 flex flex-col  rounded-lg ">

{/* make it to 2 session  */}

{/* session1  */}
<div className="flex w-full  items-center gap-4  min-h-24">
    {/* 2 rows  */}
    <div className=" flex flex-col justify-start  gap-3  min-w-1/2">
        {/* Heading  */}
        <h1 className="font-bold text-white text-[30px]">Reward</h1>
        <h1 className="bg-gradient-to-r from-[#24ddfe] to-[#578bfe] bg-clip-text text-transparent text-[30px] inter-bold">Received</h1>


    </div>
    <div className='flex justify-end pr-4 items-center p-1 w-1/2 '>
        <img className='w-16 h-16 rounded-full ' src='/images/icons/coin.png' alt="coin"/>
    </div>
</div>

{/* session2  */}
<div className=' min-h-24 pt-2 max-w-96'>
{/* text  */}

<p className='text-white font-light text-[16px] '>
You've received <span className='bg-gradient-to-r from-[#24ddfe] to-[#578bfe] bg-clip-text text-transparent  font-bold  '>150 Drob Coins</span>. Head to our store and use them to get great items and deals.
</p>

</div>
        </div>
        
        
        </>
    )
}

export default RetailerOnBoardingReceived