const TextIcon = ({color = "#FFF"}) => {

    return <>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.61765 1.94444C3.61765 2.71565 2.94705 3.38889 2.05882 3.38889C1.1706 3.38889 0.5 2.71565 0.5 1.94444C0.5 1.17324 1.1706 0.5 2.05882 0.5C2.94705 0.5 3.61765 1.17324 3.61765 1.94444Z" stroke={color}/>
            <path d="M3.61765 18.0558C3.61765 18.827 2.94705 19.5002 2.05882 19.5002C1.1706 19.5002 0.5 18.827 0.5 18.0558C0.5 17.2846 1.1706 16.6113 2.05882 16.6113C2.94705 16.6113 3.61765 17.2846 3.61765 18.0558Z" stroke={color}/>
            <path d="M19.5005 1.94444C19.5005 2.71565 18.8299 3.38889 17.9416 3.38889C17.0534 3.38889 16.3828 2.71565 16.3828 1.94444C16.3828 1.17324 17.0534 0.5 17.9416 0.5C18.8299 0.5 19.5005 1.17324 19.5005 1.94444Z" stroke={color}/>
            <path d="M19.5005 18.0558C19.5005 18.827 18.8299 19.5002 17.9416 19.5002C17.0534 19.5002 16.3828 18.827 16.3828 18.0558C16.3828 17.2846 17.0534 16.6113 17.9416 16.6113C18.8299 16.6113 19.5005 17.2846 19.5005 18.0558Z" stroke={color}/>
            <path d="M3.82422 1.94434H16.1772" stroke={color} strokeLinecap="round"/>
            <path d="M2.05957 3.61133V16.3891" stroke={color} strokeLinecap="round"/>
            <path d="M3.82422 18.0557H16.1772" stroke={color} strokeLinecap="round"/>
            <path d="M18.2354 3.88867V16.1109" stroke={color} strokeLinecap="round"/>
            <path d="M5.88184 7.50022V6.11133H9.99948M14.4112 7.50022V6.11133H9.99948M9.99948 13.8891C9.99948 13.6669 9.99948 8.61133 9.99948 6.11133M9.99948 13.8891H11.4701M9.99948 13.8891H8.23478" stroke={color} strokeLinecap="square"/>
        </svg>
    </>
}

export default TextIcon