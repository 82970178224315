import { useState } from 'react';
import './style.css'
import { userApi } from '../../../api/user.api';
import { notification } from 'antd';
import _ from 'lodash';
import { useDispatch } from "react-redux"
import { setStorageRefreshToken, setStorageToken, setStorageUserDetail } from '../../../utils/storage';
import { setUser } from '../../../redux/appSlice';
import { uploadFile } from '../../../api/upload.api';
import { UPLOADS_FOLDER } from '../../../utils/constants';

const RetailerOboardingProfilePicture=({ handleNext })=>{
  const dispatch = useDispatch()
    const [updated,setUpdated]=useState(false)
    const [image, setImage] = useState('https://i.pinimg.com/736x/1a/de/e7/1adee72fee9a94a4eacf13aaa17e40d5.jpg'); 
    const handleImageClick = () => {
        document.getElementById('fileInput').click(); // Trigger file input click
      };

      const handleFileChange = async(event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImage(reader.result); // Set the selected image
          };

          const bodyData = {
            isCompleteEnterProfile: true
        }

          const formImageData = new FormData();
          formImageData.append("file", file);
          const modelImageResult = await uploadFile(formImageData, 0, UPLOADS_FOLDER.AVATAR)
          if(modelImageResult.status && modelImageResult.status !== 200){
              notification.error({
                  message: modelImageResult.data.message
              })
              return
          }
          bodyData.avatar = modelImageResult.results


          userApi.updateLoggedInUser(bodyData).then(data => {
            setStorageUserDetail(data.user)
            dispatch(setUser(data.user))
            setStorageToken(data.tokens.access.token)
            setStorageRefreshToken(data.tokens.refresh.token)
            notification.success({
                message: "Update successfully!"
            })  }).catch(err => {
              notification.error({
                  message: _.get(err, ['response', 'data', 'message'], `Update fail!`)
              })
              
          })

          reader.readAsDataURL(file);
          setUpdated(true)
        }
      };
    return (
        <>
         <div className="w-full h-full p-3  ">
{/* first text  */}
<div className="w-full flex text-start flex-col justify-start p-4 gap-5   min-h-32 ">
    <h1 className="font-bold text-white text-[30px]">Let's Start with </h1>
    <h1 className="font-bold text-white text-[30px]">
  <span className="bg-gradient-to-r from-[#24ddfe] to-[#578bfe] bg-clip-text text-transparent">
    knowing
  </span> 
  <span> you</span>
</h1>
    <h1 className="font-bold  text-white text-[30px]">better</h1>
</div>

{/* second text  */}
<div className="w-full  min-h-10 flex justify-start items-center pl-4">
<span className="text-lg font-semibold text-white">Step 1:</span> 
<span className="text-white font-light flex  text-[15px] pl-1 pt-0.5 ">Click to add Profile Picture</span>
</div>

{/* camara sesltion otion div  */}
<div className="w-2/4 relative min-h-20 pt-4 pl-4  flex  ">
          {/* Z-index to make the div pop out without scrolling */}
          <div className="absolute top-7 left-3 w-full h-full flex justify-start items-center z-50">
            <div className="w-32 h-32 border border-white rounded-full border-dashed flex justify-center items-center">
        
            <div className="w-28 h-28 bg-white rounded-full flex justify-center items-center">
            {updated ? (
                <img
        className="w-full h-full rounded-full cursor-pointer"
        src={image}
        alt="Profile"
        onClick={handleImageClick}
      />
            ) : (
                <img
        className="w-1/2 h-1/2 cursor-pointer  "
        src='/images/icons/camera.png'
        alt="Profile"
        onClick={handleImageClick}
      />
            )}
      
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleFileChange}
      />
    </div>
            </div>
          </div>
          
        </div>
        {updated && (
 <div className=' w-full  relative  '>
 <div className='w-full absolute top-5  h-5  pr-3 justify-end flex ' onClick={handleNext}>
<img src='/images/icons/right-arrow.png' className='w-20 h-16 ' />
 </div>
  </div>
        )}
       

         </div>
        </>
    )
}

export default RetailerOboardingProfilePicture