import { useEffect } from 'react'
import './style.css'
const RetailerOnBoardingGreateJob=({ handleNext })=>{

    useEffect(() => {
        // Set a timeout of 5 seconds
        const timer = setTimeout(() => {
          handleNext();
        }, 2000);
    
       
        return () => clearTimeout(timer);
      }, []);


    return (
        <>
        <div className="w-10/12 h-full  p-4 ">
{/* fisrt heading  */}
<div className="w-full  min-h-14 flex justify-start pl-2 items-start ">
    <h1 className="text-white inter-bold text-[30px] ">Great Job!</h1>
</div>
<div className='w-full  flex pl-2 flex-col '>
    <p className='text-white font-light text-[15px]'>You'r making  great progress </p>
    <span className='text-white font-light text-[15px]'>Keep it up!</span>
</div>
        </div>
        </>
    )
}

export default RetailerOnBoardingGreateJob
