import SongsPlayer from "../../../canvasContainer/components/songsPlayer/SongsPlayer"

const AudioSetting = () => {

    return <>
        <div className='setting'>
            <SongsPlayer />
        </div>
    </>
}

export default AudioSetting