// import { Col, Row, Spin, notification } from "antd"
// import LOGO from "../../../assets/images/LOGO.svg";
// import CarouselLoginAndRegister from "../../../components/carouselLoginAndRegister/CarouselLoginAndRegister";
// import { useState } from "react";
// import authApi from "../../../api/auth.api";
// import _ from "lodash";

// import BackGround1 from "../../../assets/images/login/bg-1.png"
// import BackGround2 from "../../../assets/images/login/bg-2.png"
// import BackGround3 from "../../../assets/images/login/bg-3.png"
// import ArrowLeftIcon from "../../../assets/images/products/arrow-left.svg"
// import { useNavigate } from 'react-router-dom';
// import routesConstant from "../../../routes/routesConstant";

// const ForgotPassword = () => {
//     const navigate = useNavigate()
//     const [email, setEmail] = useState("")
//     const [loading, setLoading] = useState(false)

//     const [index, setIndex] = useState(0)
//     const [items, setItems] = useState([
//         {
//             id: 1,
//             background: BackGround1
//         },
//         {
//             id: 2,
//             background: BackGround2
//         },
//         {
//             id: 3,
//             background: BackGround3
//         }
//     ])

//     const onSubmit = () => {
//         if (!email) {
//             notification.warning({
//               message: "Email can't be null."
//             })
//             return
//         }
//         setLoading(true)
//         authApi.sendForgotPasswordEmail({email}).then(rs => {
//             notification.success({
//                 message: "Please check your email to reset password!"
//             })
//             setLoading(false)
//         }).catch(err => {
//             notification.error({
//                 message: _.get(err, ['response', 'data', 'message'], `Can't send email. Please contact Admin to change Password!`)
//             })
//             setLoading(false)
//         })
//     }

//     const onBack = () => {
//         navigate(routesConstant.login.path)
//     }

//     return <>
//     <div className='px-[32px] md:px-[73px] min-h-screen flex justify-center items-center w-full relative'>
//         {
//             items.map((el, idx) => (
//             <div
//                 className={`login-image-wrapper ${index % items.length === idx ? 'active' : ''}`}
//                 key={el.id}
//                 style={{
//                     backgroundImage: `url(${el.background})`
//                 }}
//             >
//             </div>
//             ))
//         }
//       <Row className='login__container rounded-[10px] md:rounded-[20px] overflow-hidden w-full min-h-[90vh] relative z-[2]'>
//         <Col xxl={11} xl={11} lg={11} md={11} sm={24} xs={24} className='left-col flex flex-col gap-[24px] 2xl:gap-[50px]'>
//           <div className='flex justify-start w-full'>
//             <img src={LOGO} alt="" className='h-[44px]'/>
//           </div>
//             <div className='w-full flex items-center flex-auto'>
//                 <div className='login-form'>
//                     <div className="w-full flex items-center gap-[40px]">
//                         <button className="btn-back" onClick={() => {onBack()}}>
//                             <img src={ArrowLeftIcon} alt="" />
//                             Back
//                         </button>
//                     </div>
//                     <div className='login-title mt-[87px]'>Forgot password</div>
//                     <div className='sub__login-title'>Enter your email to reset password</div>
//                     <div className='form-input-container mt-[24px] 2xl:mt-[50px]'>
//                         <div className='form-title'>
//                             Email address
//                         </div>
//                         <input
//                             type='email'
//                             className='input-text-form'
//                             placeholder='metadrob@gmail.com'
//                             value={email}
//                             onChange={(e) => {setEmail(e.target.value)}}
//                             autoComplete='off'
//                         />
//                     </div>
//                     <Spin spinning={loading} wrapperClassName="mt-[36px] xl:mt-[48px] 2xl:mt-[100px] rounded-[110px] overflow-hidden">
//                         <button className='login-button' onClick={() => {onSubmit()}}>SEND</button>
//                     </Spin>
//                 </div>
//             </div>
//         </Col>
//         <Col xxl={13} xl={13} lg={13} md={13} sm={0} xs={0} className='right-col p-[15px] md:p-[30px]'>
//             <CarouselLoginAndRegister onIndexChange={(value) => {setIndex(value)}} />
//         </Col>
//       </Row>
//     </div>
//     </>
// }
// export default ForgotPassword

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import AuthBackground from "../../../assets/images/AuthBackground.png";
import LoginFooterImage1 from "../../../assets/images/login/LoginFooterImage1.png";
import LoginFooterImage2 from "../../../assets/images/login/LoginFooterImage2.png";
import LoginFooterImage3 from "../../../assets/images/login/LoginFooterImage3.png";
import LoginFooterImage4 from "../../../assets/images/login/LoginFooterImage4.png";
import LoginFooterImage5 from "../../../assets/images/login/LoginFooterImage5.png";
import LoginFooterImage6 from "../../../assets/images/login/LoginFooterImage6.png";
import LOGO from "../../../assets/images/LOGO.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import authApi from "../../../api/auth.api";
import _ from "lodash";
import routesConstant from "../../../routes/routesConstant";
import RetailerAuthFooter from "../../../components/retailerComponents/retailerAuthFooter/RetailerAuthFooter";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = (value) => {
    if (!value.email) {
        formik.setErrors({
            email: "Email not found!", // Set the error message directly
        });
        return;
    }
    const email = value.email;
    setLoading(true);
    authApi
      .sendForgotPasswordEmail({ email })
      .then((rs) => {
        navigate(routesConstant.SentEmailForResetPassword.path);
        setLoading(false);
      })
      .catch((err) => {
        formik.setErrors({
          email: _.get(
            err,
            ["response", "data", "message"],
            `Can't send email. Please contact Admin to change Password!`
          ),
        });
        // notification.error({
        //     message: _.get(err, ['response', 'data', 'message'], `Can't send email. Please contact Admin to change Password!`)
        // });
        setLoading(false);
      });
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required")
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be less than 50 characters")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email format is incorrect"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (value) => {
      onSubmit(value);
    },
  });

    return (
        <>
            {/* For desktop */}
            <div
                // className='relative w-full h-screen pt-[3vw] justify-center items-start overflow-hidden hidden md:hidden lg:flex'
                 //className='sm:px-[32px] md:px-[73px] min-h-screen 2xl:min-h-screen hidden md:hidden lg:flex justify-center items-center  w-full relative'
                 className="sm:px-[32px] md:px-[73px] min-h-screen lg:min-h-[1080px] hidden md:hidden 3xl:min-h-screen  lg:flex justify-center items-center lg:items-baseline 3xl:items-center pt-16  w-full relative"
            >

                <div
                    style={{
                        backgroundImage: `url(${AuthBackground})`,
                        backgroundSize: "cover",
                    }}
                    className={`image-wrapper`}
                >
                    <div className="mx-auto pt-[7%] pb-[15%] sm:pb-0 sm:pl-10 sm:pt-10 w-full ">
                        <img
                            src={LOGO}
                            alt=""
                            className="h-8 mx-auto sm:mx-0 sm:h-[33px]"
                        />
                    </div>
                    <div className="footer-login" >
                        <RetailerAuthFooter />
                    </div>
                </div>



                {/* Main container for the reset password form */}
                <div className="w-full log-box h-[90vh] xl:h-2/3 sm:w-2/3 lg:w-2/5 bg-[#060606] rounded-[9px] sm:rounded-[16px] overflow-hidden absolute bottom-0 sm:relative z-10">

                    <div className='w-[36vw] min-h-[35vw] bg-[#060606] rounded-lg pt-[6vw]'>

                        {/* Top part of the form */}
                        <div className='text-white flex flex-col justify-center items-center gap-6 border-b border-b-zinc-700 mx-20 pb-[10vw]'>

                            {/* Heading section */}
                            <div className='w-full flex flex-col items-center'>
                                <h1 className='text-[2vw] font-semibold mb-3'>Reset Password</h1>
                                <div className='w-full flex flex-col items-center justify-center'>
                                    <p className='text-[0.85vw] font-light'>All good, Enter your account’s email address and</p>
                                    <p className='text-[0.85vw] font-light'>we’ll send you a link to reset your password.</p>
                                </div>
                            </div>

                            {/* Input fields for email */}
                            <form onSubmit={formik.handleSubmit}>
                                <div className='flex flex-col gap-4'>
                                    <div className='flex flex-col gap-1 items-start'>
                                        <label className='text-sm'>Email</label>
                                        <input value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className='w-[18vw] h-[2.3vw] rounded-[0.5vw] px-3 outline-none bg-transparent border'
                                            type="email"
                                            id="email" name="email"
                                            placeholder='Enter your email'
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <span className="text-red-400 text-left text-[10px] font-light">{formik.errors.email}</span>
                                        ) : null}
                                        {/* <h1 className='text-sm'>Email not found!</h1> */}
                                    </div>

                                    {/* Button to send reset link */}
                                    <button className='bg-white text-black rounded-[0.5vw] py-2 cursor-pointer' disabled={loading} type="submit">{loading ? <div className="flex justify-center"><l-ring size="17"
                                        stroke="2"
                                        bg-opacity="0"
                                        speed="2"
                                        color="black"
                                    ></l-ring> <span className="ml-2" >Loading </span></div> : "Send Reset Link"}</button>
                                </div>
                            </form>

                            {/* Return to login page */}
                            <NavLink className='flex items-center gap-2'>
                                <div><FaArrowLeft /></div>
                                <NavLink to="/login" className='cursor-pointer'>Return to login</NavLink>
                            </NavLink>

                        </div>

                        {/* Bottom part of the component */}
                        <div className='w-full flex flex-col items-center justify-center pt-4 text-white'>
                            <div>
                                <p className='text-zinc-500'>The most innovative companies supported metadrob</p>
                            </div>

                            {/* Logos of companies */}
                            <div className='flex items-center justify-center gap-2'>
                                <div><img className='w-[4vw]' src={`${LoginFooterImage1}`} alt="" /></div>
                                <div><img className='w-[3vw]' src={`${LoginFooterImage2}`} alt="" /></div>
                                <div><img className='w-[4vw]' src={`${LoginFooterImage3}`} alt="" /></div>
                                <div><img className='w-[4vw]' src={`${LoginFooterImage4}`} alt="" /></div>
                                <div><img className='w-[4vw]' src={`${LoginFooterImage5}`} alt="" /></div>
                                <div><img className='w-[4vw]' src={`${LoginFooterImage6}`} alt="" /></div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* For tablet */}
            <div className="w-full h-screen items-center justify-center overflow-hidden hidden md:flex lg:hidden">

                <img
                    src={LOGO}
                    alt="Main Logo"
                    className="absolute top-5 left-4 h-8"
                />

                <div className=''>
                    <img className='w-full h-screen absolute left-0 top-0 -z-[1] brightness-50' src={`${AuthBackground}`} alt="" />
                </div>

                <div className="w-[70vw] flex items-center justify-center bg-black text-white">
                    <div className="bg-black bg-opacity-70 p-8 rounded-lg">
                        <h2 className="inter pt-12 text-2xl font-semibold mb-6 text-center">Reset Password</h2>
                        <p className=" font text-sm mb-6 text-center">All good, Enter your account's email address and <br /> we'll send you a link to reset your password.</p>

                        <form className="py-3" onSubmit={formik.handleSubmit}>
                            
                            <div className='flex flex-col gap-1'>
                                <label className='text-sm'>Email</label>
                                <input value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className='w-[50vw] p-3 rounded-xl bg-black border-600 text-white'
                                    type="email"
                                    id="email" name="email"
                                    placeholder='Enter your email'
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <span className="text-red-400 text-left text-[10px] font-light">{formik.errors.email}</span>
                                ) : null}
                                {/* <h1 className='text-sm'>Email not found!</h1> */}
                            </div>


                            <button className='mt-8 w-full py-3  rounded-xl bg-white text-black font-medium text-base cursor-pointer' disabled={loading} type="submit">{loading ? <div className="flex justify-center"><l-ring size="17"
                                stroke="2"
                                bg-opacity="0"
                                speed="2"
                                color="black"
                            ></l-ring> <span className="ml-2" >Loading </span></div> : "Send Reset Link"}</button>
                        </form>

                        <NavLink to="/login" className="inter w-full mt-8 text-sm text-white  flex justify-center items-center border-b border-b-zinc-700 pb-20">
                            <HiOutlineArrowLeft className="mr-2" /> Return to login
                        </NavLink>



                        <div className="font text-center text-sm pt-2 text-[#939393]">

                            <p>The most innovative companies supported metadrob</p>
                            <div className="flex justify-center mt-4 space-x-4">
                                <img src={`${LoginFooterImage1}`} alt="" className="h-6" />
                                <img src={`${LoginFooterImage2}`} alt="" className="h-6" />
                                <img src={`${LoginFooterImage3}`} alt="" className="h-6" />
                                <img src={`${LoginFooterImage4}`} alt="" className="h-6" />
                                <img src={`${LoginFooterImage5}`} alt="" className="h-6" />
                                <img src={`${LoginFooterImage6}`} alt="" className="h-6" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* For mobile devices */}
            <div className='w-full h-screen items-start justify-center pt-[20vw] overflow-hidden flex md:hidden lg:hidden'>

                <div className="w-[100vw]">
                    <img
                        src={LOGO}
                        alt="Main Logo"
                        className="absolute top-6 left-1/2 transform -translate-x-[50%] h-8"
                    />
                </div>

                <div className=''>
                    <img className='w-full h-screen absolute left-0 top-0 -z-[1] brightness-50' src={`${AuthBackground}`} alt="" />
                </div>

                <div className='w-[100vw] h-[100vh] bg-[#060606] rounded-lg pt-[12vw]'>

                    {/* Top part of the form */}
                    <div className='text-white flex flex-col justify-center items-center gap-6'>

                        {/* Heading section */}
                        <div className='w-full flex flex-col items-center'>
                            <h1 className='text-[6vw] font-semibold mb-3'>Reset Password</h1>
                            <div className='w-full flex flex-col items-center justify-center'>
                                <p className='w-[100vw] text-[3vw] text-center font-light'>All good, Enter your account’s email address and</p>
                                <p className='w-[100vw] text-[3vw] text-center font-light'>we’ll send you a link to reset your password.</p>
                            </div>
                        </div>

                        {/* Input fields for email */}
                        <form action="" onSubmit={formik.handleSubmit}>
                            <div className='flex flex-col gap-4'>
                               
                                <div className='flex flex-col gap-1 items-start'>
                                    <label className='text-xs'>Email</label>
                                    <input value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className='w-[75vw] rounded-md bg-transparent border outline-none px-3 py-1'
                                        type="email"
                                        id="email" name="email"
                                        placeholder='Enter your email'
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <span className="text-red-400 text-left text-[10px] font-light">{formik.errors.email}</span>
                                    ) : null}
                                    {/* <h1 className='text-sm'>Email not found!</h1> */}
                                </div>
                            </div>

              <button
                className="mt-8 w-full py-3 rounded-xl bg-white text-black font-medium text-base cursor-pointer"
                disabled={loading}
                type="submit"
              >
                {loading ? (
                  <div className="flex justify-center">
                    <span className="ml-2">Loading </span>
                  </div>
                ) : (
                  "Send Reset Link"
                )}
              </button>
            </form>

            <NavLink
              to="/login"
              className="inter w-full mt-8 text-sm text-white flex justify-center items-center border-b border-b-zinc-700 pb-20"
            >
              <HiOutlineArrowLeft className="mr-2" /> Return to login
            </NavLink>

            {/* <div className="text-center text-sm pt-5">
              The most innovative companies supported metadrob
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
