const NoLockIcon = () => {

    return <>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="3" fill="black" fillOpacity="0.8"/>
            <path d="M19.125 13.75H12.375V10.375C12.375 9.77826 12.6121 9.20597 13.034 8.78401C13.456 8.36205 14.0283 8.125 14.625 8.125C15.2217 8.125 15.794 8.36205 16.216 8.78401C16.6379 9.20597 16.875 9.77826 16.875 10.375H18C18 9.47989 17.6444 8.62145 17.0115 7.98851C16.3786 7.35558 15.5201 7 14.625 7C13.7299 7 12.8714 7.35558 12.2385 7.98851C11.6056 8.62145 11.25 9.47989 11.25 10.375V13.75H10.125C9.82663 13.75 9.54048 13.8685 9.3295 14.0795C9.11853 14.2905 9 14.5766 9 14.875V21.625C9 21.9234 9.11853 22.2095 9.3295 22.4205C9.54048 22.6315 9.82663 22.75 10.125 22.75H19.125C19.4234 22.75 19.7095 22.6315 19.9205 22.4205C20.1315 22.2095 20.25 21.9234 20.25 21.625V14.875C20.25 14.5766 20.1315 14.2905 19.9205 14.0795C19.7095 13.8685 19.4234 13.75 19.125 13.75ZM19.125 21.625H10.125V14.875H19.125V21.625Z" fill="white"/>
        </svg>
    </>
}

export default NoLockIcon