import './style.css'
const RetailerOnBoardingKeepUp=({ handleNext,handleSkip })=>{
   


    return (
        <>
        {/* main div component */}
        <div className="w-full h-full p-4 ">

{/* session 1  */}
<div className="w-full min-h-24 flex justify-center items-center flex-col gap-5">
<h1 className="font-bold text-white text-[25px] inter-bold">Good job keeping up </h1>
<p className='text-white font-light flex  text-[15px]'>Now it's time to do someting exciting</p>
</div>
{/* session 1  */}


{/* session 2  */}
<div className='w-full flex justify-center items-center '>
<button className='bg-gradient-to-r from-[#24ddfe] to-[#9d17ff] min-h-10 min-w-20 rounded-lg text-white font-semibold ' onClick={handleSkip}>
    Try Editor
</button>
</div>
{/* session 2  */}

         {/* main div component */}   
        </div>
        </>
    )
}

export default RetailerOnBoardingKeepUp