import './style.css'
const RetailerOnBoardingSocialConnection=({ handleNext })=>{
    return (
        <>
        <div className="w-10/12 h-full  rounded-lg">
{/* fist heading */}
<div className="w-full flex text-start flex-col justify-start p-4 gap-3   min-h-32">
    <h1 className="inter-bold text-white text-[30px]">Connect</h1>
    <div  className='min-h-7  w-full  flex items-center pt-1 '>

    <h1 className="bg-gradient-to-r from-[#24ddfe] h-7  to-[#9b1afe] inline-block  text-transparent inter-bold  bg-clip-text  text-[30px] ">Social Media</h1>
    </div>
    <h1 className="inter-bold text-white text-[30px]">Account</h1>
    </div>

    {/* step 2 div  */}
    <div className="w-full  min-h-10 flex  flex-col pl-4 ">
        <div className='flex'>
        <p className="text-lg font-semibold text-white">Step 2:</p> 
        <p className="text-white font-light flex items-center text-[15px] pl-1 pt-0.5 ">Select the accounts you want to connect</p>
        </div>

{/* <p className='text-white font-light flex items-center text-[15px]'></p> */}
</div>
    {/* social medias list  */}
    <div className="w-full  min-h-36 p-5  flex flex-col gap-4">
        {/* facebook  */}
        <div className='w-full flex  gap-6 items-center '>
            {/* icon  */}
            <img className='w-10 h-10 rounded-full  ' src='/images/icons/facebook.png' />
            {/* butten */}
        <button className='bg-white rounded-3xl min-w-28 lg:min-w-32 min-h-8 h-8 pl-2 pr-2 text-black hover:bg-black hover:text-white hover:border hover:cursor-pointer '>
            Connect
        </button>
        </div>

        {/* X  */}
        <div className='w-full flex  gap-6 items-center '>
            {/* icon  */}
            <img className='w-10 h-10 rounded-full  ' src='/images/icons/twitter.png' />
            {/* butten */}
        <button className='bg-white rounded-3xl min-w-28 lg:min-w-32 min-h-8 h-8 pl-2 pr-2 text-black hover:bg-black hover:text-white hover:border hover:cursor-pointer'>
            Connect
        </button>
        </div>

        {/* instagram  */}
        <div className='w-full flex  gap-6 items-center '>
            {/* icon  */}
            <img className='w-10 h-10 rounded-full  ' src='/images/icons/instagram.png' />
            {/* butten */}
        <button className='bg-white rounded-3xl min-w-28 lg:min-w-32 min-h-8 h-8 pl-2 pr-2 text-black hover:bg-black hover:text-white hover:border hover:cursor-pointer'>
            Connect
        </button>
        </div>
    </div>
    <div className=' w-full  relative  '>
 <div className='w-full absolute top-3  h-5  pr-3 justify-end flex 'onClick={handleNext}>
<img src='/images/icons/right-arrow.png' className='w-20 h-16 ' />
 </div>
  </div>
        </div>
        
        </>
    )
}

export default RetailerOnBoardingSocialConnection